import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"
import useWindowSize from "../Hooks/useWindowSize"
import useRichText from "../Hooks/useRichText"
import TextBox from "../components/TextBox/TextBox"
import GoBack from "../components/GoBack/GoBack"
import useViewport from "../Hooks/useViewport"

export const query = graphql`
  query ($slug: String!) {
    contentfulProyectos(url: { eq: $slug }) {
      titleSeo
      descriptionSeo {
        descriptionSeo
      }
      nombre
      ano
      url
      descripcion {
        raw
      }
      lugar
      imgMobileDetalle {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      }
      imgDesktopDetalle {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      }
    }
  }
`

const Proyecto = props => {
  const size = useWindowSize()
  const text = useRichText(props.data.contentfulProyectos.descripcion)
  useViewport();

  return (
    <Layout>
      <Seo
        title={props.data.contentfulProyectos.nombre}
        lang="es"
        titleSEO={props.data.contentfulProyectos.titleSeo}
        description={props.data.contentfulProyectos.descriptionSeo.descriptionSeo}
      />

      <div className="Proyecto">
        {size > 880 ? (
          <>
            <div className="row">
              <div className="col-11">
                <div className="Proyecto__titles">
                  <h1>{props.data.contentfulProyectos.nombre}</h1>
                  <h6>
                    {props.data.contentfulProyectos.lugar},{" "}
                    {props.data.contentfulProyectos.ano}
                  </h6>
                </div>
              </div>
              <div className="col-1">
                <GoBack />
              </div>
            </div>

            <div className="Proyecto__text">{text}</div>

            <div className="Proyecto__imgs">
              {props.data.contentfulProyectos.imgDesktopDetalle.map(
                (img, i) => (
                  <GatsbyImage
                    image={getImage(img)}
                    style={{ marginBottom: 30 }}
                    alt="img"
                    key={i}
                    className="Proyecto__imgs--item"
                  />
                )
              )}
            </div>
          </>
        ) : (
          <>
            <div className="Proyecto__titles">
              <h1>{props.data.contentfulProyectos.nombre}</h1>
              <h6>
                {props.data.contentfulProyectos.lugar},{" "}
                {props.data.contentfulProyectos.ano}
              </h6>
            </div>
            <div className="Proyecto__text">
              <TextBox>{text}</TextBox>
            </div>
            <div className="Proyecto__imgs">
              {props.data.contentfulProyectos.imgMobileDetalle.map((img, i) => (
                <GatsbyImage
                  image={getImage(img)}
                  style={{ marginBottom: 30 }}
                  alt="img"
                  key={i}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default Proyecto
