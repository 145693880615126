import React, { useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import { BiChevronUp } from "react-icons/bi"
import "./TextBox.scss"

const TextBox = ({ children }) => {
  const [wider, setWider] = useState(true)

  const onClick = () => {
    setWider(!wider)
    if (window !== undefined) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  return (
    <>
      <div
        className={`${
          wider ? "TextBox TextBox__small" : "TextBox TextBox__large"
        }`}
      >
        {children}
        <button onClick={onClick} className="TextBox--readMore">
          {wider ? (
            <BiChevronDown fontSize="1.2rem" />
          ) : (
            <BiChevronUp fontSize="1.2rem" />
          )}
        </button>
      </div>
    </>
  )
}

export default TextBox
