import React from "react";
import { navigate } from "gatsby";
import { IoMdReturnLeft } from 'react-icons/io'
import './GoBack.scss'

export default function GoBack() {
  return (
    <button className="GoBack" onClick={() => navigate(-1)}>
      <IoMdReturnLeft />
    </button>
  );
}